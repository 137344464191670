import { Chain, configureChains, createConfig } from 'wagmi';
import {
  boba,
  linea,
  polygon
} from 'wagmi/chains';
import {MetaMaskConnector} from 'wagmi/connectors/metaMask';
import {WalletConnectConnector} from 'wagmi/connectors/walletConnect';
import {CoinbaseWalletConnector} from 'wagmi/connectors/coinbaseWallet';
import { publicProvider } from 'wagmi/providers/public';
import { InjectedConnector } from 'wagmi/connectors/injected';

// const xdcNetwork = {...xdc, name: 'XDC Network'}

export const xdcNetwork = {
  id: 50,
  name: 'XDC Network',
  network: 'xdc',
  nativeCurrency: {
    decimals: 18,
    name: 'XDC',
    symbol: 'XDC',
  },
  rpcUrls: {
    default: { http: ['https://erpc.xinfin.network'] },
    public: { http: ['https://erpc.xinfin.network'] },
  },
  blockExplorers: {
    etherscan: { name: 'XDC Network', url: 'https://explorer.xinfin.network' },
    default: { name: 'Blocksscan', url: 'https://explorer.xinfin.network' },
  }
} as const satisfies Chain

const {chains, publicClient, webSocketPublicClient} = configureChains(
  [
    boba,
    xdcNetwork,
    linea,
    polygon
  ],
  [publicProvider()]
);

export const config = createConfig({
  autoConnect: true,
  connectors: [
    new InjectedConnector({
      chains,
      options: {
        shimDisconnect: false,
        name: 'Injected',
      }
    }),
    new InjectedConnector({
      chains,
      options: {
        shimDisconnect: false,
        name: 'Trust Wallet',
      getProvider: () => {
        // @ts-ignore
        return window && window.hasOwnProperty("trustwallet") ? window.trustwallet : undefined}
      },
    }),
    new MetaMaskConnector({ chains }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: "0720e13e824ef7b9aa2c932fbe7087d2",
        metadata: {
          name: 'WeFi.xyz',
          description: 'Supply single sided liquidity to earn risk free yield or use leveraged investment vaults to supercharge your DeFi strategies.',
          url: window.location.href,
          icons: ['https://s1.coincarp.com/logo/1/wefi-xyz.png?style=200&v=1684455707']
        }
      },
    }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: "WeFi.xyz"
      }
    })
  ],
  publicClient,
  webSocketPublicClient
});

export const availableChains = chains;
